import * as React from 'react'
import PropTypes from 'prop-types'
import './lines.css'

const widthTopFirstLines = [100, 200, 100]
const widthTopSecondLines = [176, 136, 66]
const widthBottomFirstLines = [200, 100, 100, 200, 100, 100, 200, 100]
const widthBottomSecondLines = [136, 66, 176, 136, 66, 176, 136, 66]

/**
 * Line Component. Create a individual horizontal line (this line is divided in two parts)
 * @param {Object} props - Object with these properties:
 *  @param {String} widthFirstLine - with of the first part of the line
 *  @param {String} widthSecondLine - width of the second part of the line
 * @returns Line as JSX Component
 */
const Line = ({ widthFirstLine, widthSecondLine }) => {
  return (
    <div className='gl-line'>
      <div className='gl-line__first-half' style={{ width: widthFirstLine + 'px' }} />
      <div className='gl-line__second-half' style={{ width: widthSecondLine + 'px' }} />
    </div>
  )
}

const topLines = widthTopFirstLines.map((widthFirstLine, i) => <Line key={'top-line-' + i} widthFirstLine={widthFirstLine} widthSecondLine={widthTopSecondLines[i]} />)
const bottomLines = widthBottomFirstLines.map((widthFirstLine, i) => <Line key={'bottom-line-' + i} widthFirstLine={widthFirstLine} widthSecondLine={widthBottomSecondLines[i]} />)

/**
 * Lines Component. Create a set of horizontal lines
 * @param {Object} props - Object with these properties:
 *  @param {String} location - location of lines in the markup. Can be 'top' or 'bottom'
 * @returns Lines as JSX Component
 */
const Lines = ({ location }) => {
  if (location === 'bottom') return bottomLines
  else return topLines
}

Line.propTypes = {
  widthFirstLine: PropTypes.number.isRequired,
  widthSecondLine: PropTypes.number.isRequired
}

Lines.defaultProps = {
  location: 'top'
}

Lines.propTypes = {
  location: PropTypes.string
}

export default Lines
