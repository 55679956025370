import * as React from 'react'
import Lines from '../../components/lines/lines'
import './about.css'

const About = () => {
  return (
    <div className='gl-about'>
      <div className='gl-about__panel-background' />
      <div className='gl-about__panel'>
        <Lines location='top' />
        <h1 className='gl-about__title'><span className='gl-about__span-title'>_</span>GERSON<br />LÁZARO<span className='gl-about__span-title'>_</span></h1>
        <Lines location='bottom' />
      </div>
      <div className='gl-about__panel gl-about__panel--right'>
        <p className='gl-about__text'>Desarrollador de software y escritor aficionado. Resuelvo problemas. Javascriptmaniático.</p>
        <p className='gl-about__text'>Ingeniero de sistemas de la Universidad Francisco de Paula Santander (2018). Me apasionan las nuevas tecnologías y el desarrollo web. Soy frontend engineer en MercadoLibre.</p>
        <p className='gl-about__text'>Actualmente enfocado en Javascript y React.</p>
        <h2 className='gl-about__subtitle'>Algunos proyectos</h2>
        <ul className='gl-about__list'>
          <li className='gl-about__list-element'>
            <a className='gl-about__link' href='https://trainingcenter.cloud.ufps.edu.co/iniciar-sesion' target='_blank' rel='noreferrer'>UFPS Training Center:</a> Plataforma de entrenamiento y enseñanza en programación competitiva, con juez evaluador de código fuente para C++, Java y Python y competencias de programación en tiempo real.
          </li>
          <li className='gl-about__list-element'>
            <a href='https://gersonlazaro.com/aprende-x-si-sabes-y/' className='gl-about__link' target='_blank' rel='noreferrer'>Aprende X si sabes Y:</a> Webapp estática de código libre para comparar snippets cortos de códigos en diferentes lenguajes.
          </li>
          <li className='gl-about__list-element'>
            <a href='http://ufpstrainingcenter.com/#/material-publico' className='gl-about__link' target='_blank' rel='noreferrer'>Programación competitiva:</a> Participé en 4 maratones de programación nacionales (Colombia) y 4 regionales (latinoamérica norte). Fui promotor y lider del grupo de estudio en programación competitiva UFPS buscando la consolidación de una comunidad alrededor de esta disciplina.
          </li>
        </ul>
        <h2 className='gl-about__subtitle'>Otras aficiones</h2>
        <p className='gl-about__text'>Escritor aficionado. Comparto en este sitio pequeñas historias y relatos (trágicos por lo general).</p>
        <h2 className='gl-about__subtitle'>También puedes encontrarme en:</h2>
        <ul className='gl-about__list'>
          <li className='gl-about__list-element'>
            <a href='https://medium.com/@gersonlazaroc' className='gl-about__link' target='_blank' rel='noreferrer'>Medium:</a> Sitio de escritos personales.
          </li>
          <li className='gl-about__list-element'>
            <a href='https://github.com/gersonLazaro/' className='gl-about__link' target='_blank' rel='noreferrer'>Github:</a> Mis proyectos personales.
          </li>
          <li className='gl-about__list-element'>
            <a href='hhttps://co.linkedin.com/in/gersonlazaro' className='gl-about__link' target='_blank' rel='noreferrer'>Linkedin:</a> Entrando en la formalidad, mi CV.
          </li>
          <li className='gl-about__list-element'>
            <a href='hhttps://www.goodreads.com/user/show/12955589-gerson-l-zaro' className='gl-about__link' target='_blank' rel='noreferrer'>Goodreads:</a> Si te gusta la lectura, tienes libros por recomendar, o quieres ver mis recomendaciones, agregame aquí.
          </li>
        </ul>
      </div>
    </div>
  )
}

export default About
