import * as React from 'react'
import Layout from '../components/layout/layout'
import Seo from '../components/seo/seo'
import About from '../parts/about/about'

const GersonLazaro = ({ location }) => {
  return (
    <Layout location={location} title='Acerca de'>
      <Seo
          title='¿Quién soy? - Gerson Lázaro'
          description='Desarrollador de software y contador de historias'
          url={location?.href}
          image="https://www.gersonlazaro.com/logo-gerson-lazaro.png"
          lang="es"
        />
      <About />
    </Layout>
  )
}

export default GersonLazaro
